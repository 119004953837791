<template>
  <div class="app-container">
    <vxe-table
      border
      resizable
      height="600px"
      :expand-config="{expandAll: true}"
      :data="tableData"
    >
      <vxe-column type="expand" width="60">
        <template #content="{ row }">
          <vxe-table
            ref="xTable"
            border
            :footer-method="footerMethod"
            show-footer
            :data="row.detailList"
            :cell-style="cellStyle"
            :edit-config="{trigger: 'click', mode: 'cell'}"
            :edit-rules="detailRules"
          >
            <vxe-column title="序号" type="seq" width="60" />
            <vxe-column field="specificationModel" title="规格型号" />
            <vxe-column field="sku" title="Sku" />
            <vxe-column field="fnsku" title="Fnsku" />
            <vxe-column field="upc" title="UPC" />
            <vxe-column field="expectTransferNumber" title="预计调拨数量" />
            <vxe-column field="haveTransferWarehouseNumber" title="已转数量" />
            <vxe-column field="transferWarehouseNumber" title="转仓库作业数量" :edit-render="{}" prop="transferWarehouseNumber">
              <template #edit="{ row }">
                <el-input-number
                  v-model="row.transferWarehouseNumber"
                  :min="0"
                  :max="row.expectTransferNumber-row.haveTransferWarehouseNumber"
                  :precision="0"
                  controls-position="right"
                  style="width: 100%;"
                  @input="updateFooterEvent()"
                  @blur="toWareNum(row)"
                />
              </template>
            </vxe-column>
            <vxe-column field="noTransferWarehouseNumber" title="剩余未转数量">
              <template #default="{ row }">
                <span>{{ countAmount(row) }}</span>
              </template>
            </vxe-column>
          </vxe-table>
        </template>
      </vxe-column>
      <vxe-column title="序号" type="seq" width="60" />
      <vxe-column field="transferOrderCode" title="调拨单号" />
      <vxe-column field="transferTypeI18" title="调拨类型" />
      <vxe-column field="outStockChannelName" title="调出备货渠道" />
      <vxe-column field="inStockChannelName" title="调入备货渠道" />
      <vxe-column field="outWarehouseName" title="调出仓库" />
      <vxe-column field="inWarehouseName" title="调入仓库" />
      <vxe-column field="expectShelfDate" title="期望上架日期" />
      <vxe-column field="tipList" title="提示信息" />
    </vxe-table>
    <el-row class="grid-content bg-blue" type="flex" justify="center" style="margin-top: 20px;">
      <el-button type="primary" @click="goBack">取消</el-button>
      <el-button :loading="submitLoading" type="primary" @click="onSubmit">提交</el-button>
    </el-row>
  </div>
</template>
<script>
import { transferWarehouseView, transferWarehouse } from '@/api/scm-api'
export default {
  data() {
    return {
      tableData: [],
      detailRules: { transferWarehouseNumber: [{ required: true, message: '必填' }] },
      confirmFlag: false,
      submitLoading: false
    }
  },
  computed: {
    isIframe() {
      return this.$route.path.includes('/iframe/')
    }
  },
  mounted() {
    this.queryClick()
  },
  methods: {
    async queryClick() {
      const selectTosure = []
      selectTosure.push(this.$route.query.selectTosure)
      const { code, datas } = await transferWarehouseView(selectTosure)
      if (code === 0) {
        this.tableData = datas
      }
    },
    updateFooterEvent() {
      const $table = this.$refs.xTable
      $table.updateFooter()
    },
    toWareNum(row) {
      if (!row.transferWarehouseNumber) {
        row.transferWarehouseNumber = 0
      }
    },
    countAmount(row) {
      const { expectTransferNumber, haveTransferWarehouseNumber, transferWarehouseNumber } = row
      row.noTransferWarehouseNumber = Number(expectTransferNumber || 0) - Number(haveTransferWarehouseNumber || 0) - Number(transferWarehouseNumber || 0)
      return row.noTransferWarehouseNumber
    },
    countAllAmount(data) {
      let count = 0
      data.forEach(row => {
        count += this.countAmount(row)
      })
      return count
    },
    sumNum(list, field) {
      let count = 0
      list.forEach(item => {
        count += Number(item[field] || 0)
      })
      return count
    },
    footerMethod({ columns, data }) {
      return [
        columns.map((column, columnIndex) => {
          if (columnIndex === 0) {
            return '合计'
          }
          if (['expectTransferNumber', 'haveTransferWarehouseNumber', 'transferWarehouseNumber'].includes(column.property)) {
            return this.sumNum(data, column.property)
          }
          if (column.property === 'noTransferWarehouseNumber') {
            return this.countAllAmount(data)
          }
          return null
        })
      ]
    },
    goBack() {
      this.$router.push({
        name: this.isIframe ? 'IframeTransferOrderManagement' : 'TransferOrderManagement'
      })
    },
    async onSubmit() {
      try {
        this.submitLoading = true
        const selectedTableData = this.tableData.map(item => {
          const newItem = { ...item } // 创建一个副本，避免修改原始数据
          newItem.detailList = item.detailList.filter(i => i.transferWarehouseNumber !== 0)
          return newItem
        })
        let isSum = false
        for (const item of selectedTableData) {
          if (item.detailList.length === 0) {
            isSum = true
            break
          }
        }
        if (isSum) return this.$message.warning('每个调拨单的调拨数量总数不得为0')
        const toWareParam = Object.assign({}, { orderList: selectedTableData }, { confirmFlag: this.confirmFlag })
        const { code, datas } = await transferWarehouse(toWareParam)
        if (code === 0) {
          if (!datas) {
            this.confirmFlag = false
            this.goBack()
          }
          if (this.confirmFlag) {
            this.goBack()
            return
          }
          if (datas.length > 0) {
            // 遍历
            this.tableData.map(item => datas.map(i => {
              if (i.transferOrderCode === item.transferOrderCode) {
                item.tipList = i.tipList.join(';')
              }
            }))

            this.$message.warning('当前转仓库作业存在异常，请查看各调拨单提示信息')
            this.confirmFlag = true
          } else {
            this.confirmFlag = false
            this.goBack()
          }
        }
        this.submitLoading = false
      } finally {
        this.submitLoading = false
      }
    },
    cellStyle({ row, column }) {
      if (row.tipList) {
        return {
          color: 'orange'
        }
      }
    }
  }
}
</script>

